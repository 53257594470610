<template>
  <v-progress-linear :value="progress" color="primary" rounded height="12">
    <span class="black--text progress-percent">{{ progress }}%</span>
  </v-progress-linear>
</template>

<script>
  import Rotation from '@/services/classes/Rotation.js'

  export default {
    name: 'RotationProgress',
    props: {
      rotation: {
        type: Rotation,
        required: true
      }
    },
    computed: {
      progress() {
        return this.rotation.smartProgress ? Math.round(this.rotation.smartProgress * 100) : 0
      }
    }
  }
</script>

<style lang="scss" scoped>
  :deep .v-progress-linear__background {
    opacity: 0.4 !important;
  }
  .progress-percent {
    font-size: 10px;
  }
</style>
