<template>
  <v-card elevation="0" class="border-secondary pa-3" height="100%">
    <v-card-title class="black--text text-body-2 pa-0">
      <span class="mr-2">
        {{ title }}
      </span>
      <span v-if="!singleTarget" class="text-caption font-weight-medium primary--text">
        {{ items.length }}
      </span>
    </v-card-title>
    <v-card-text class="pa-0">
      <card-items :items="items" :limit="itemLimits" :no-limits="noLimits" />
    </v-card-text>
  </v-card>
</template>

<script>
  import CardItems from './CardItems.vue'

  export default {
    name: 'TargetCard',
    components: { CardItems },
    props: {
      limit: {
        type: Number,
        default: 7
      },
      noLimits: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      singleTarget: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        itemLimits: this.limit || 7
      }
    }
  }
</script>
