<template>
  <v-card elevation="0" class="border-secondary" height="100%">
    <v-card-title class="black--text opacity-60 text-body-2 pa-3 pb-0">
      {{ title }}
    </v-card-title>
    <v-card-text class="px-3 pt-0 pb-1">
      <card-items :items="parseIpRanges" />
      <c-btn
        small
        outlined
        color="primary"
        :loading="isLoading"
        :label="$t('campaign.targets.parse')"
        @click="parsingIp()"
      />
      <div v-if="countriesName.length" class="mt-2">
        {{ $t('campaign.targets.countries') }}
        <card-items :items="countriesName" />
      </div>

      <div v-if="carriersName.length" class="mt-2">
        {{ $t('campaign.targets.carriers') }}
        <card-items :items="carriersName" :limit="itemLimits" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CardItems from './CardItems.vue'
  import ipRangesRepository from '@/services/repositories/ip-ranges-repository.js'
  import formatIpRanges from '@/services/utils/format-ip-ranges-string.js'

  export default {
    name: 'IpRangeCard',
    components: {
      CardItems,
      CBtn
    },
    props: {
      title: {
        type: String,
        required: true
      },
      ipRanges: {
        type: String,
        required: true
      },
      limit: {
        type: [Number, Boolean],
        default: 10
      }
    },
    data() {
      return {
        isLoading: false,
        countriesById: [],
        carriersById: [],
        itemLimits: this.limit || 10
      }
    },
    computed: {
      ...mapState('collections', ['countries', 'carriers']),
      countriesName() {
        return this.countriesById.map((id) => {
          const foundCountry = this.countries.find((country) => country.id === id)
          return foundCountry ?? {}
        })
      },
      carriersName() {
        return this.carriersById.map((id) => {
          const foundCarrier = this.carriers.find((carrier) => carrier.id === id)
          return foundCarrier ?? {}
        })
      },
      parseIpRanges() {
        const formatedIpRanges = formatIpRanges(this.ipRanges).split(',')
        return formatedIpRanges.map((ip) => ({ name: ip }))
      }
    },
    methods: {
      ...mapActions('collections', ['fetchCountriesCollection', 'fetchCarriersCollection']),
      parsingIp() {
        this.fetchCountriesCollection()
        this.fetchCarriersCollection()
        this.fetchParsingIpRange()
      },
      async fetchParsingIpRange() {
        this.isLoading = true
        try {
          const { data } = await ipRangesRepository.parse(this.ipRanges)
          this.countriesById = data.country_ids
          this.carriersById = data.carrier_ids
        } catch {
          this.$showErrorNotification(this.$t('campaign.targets.error_parse_ip_range'))
        }
        this.isLoading = false
      }
    }
  }
</script>
