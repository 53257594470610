<template>
  <v-card class="fill-height py-4 px-3 d-md-flex flex-column justify-space-between" elevation="0">
    <div :class="['width-200 pt-1 d-flex flex-wrap justify-space-between align-center mb-sm-9 mb-md-0', { picker: isMobile }]">
      <date-picker-select
        :value="statisticsDates"
        :text-field-props="{ backgroundColor: 'white' }"
        with-presets
        no-label
        buttons
        @input="$emit('update-dates', $event)"
      />
      <div class="my-4 my-sm-0 d-flex justify-center text-caption text-sm-body-2 text-capitalize">
        <span class="legend-item legend-item--cost warning--text">
          {{ $t('campaign.cost') }}
        </span>
        <span class="legend-item info--text ml-8">
          {{ $t('campaign.impressions') }}
        </span>
      </div>
      <c-btn
        v-if="!isMobile"
        :label="$t('campaign.go_to_statistics')"
        class="px-2 primary--text text-sm-body-2 text-decoration-underline font-weight-regular"
        text
        @click="goToStatistics()"
      />
    </div>
    <div class="relative">
      <div v-if="statisticsIsLoading" class="d-flex justify-center">
        <v-progress-circular
          class="my-5"
          width="10"
          size="83"
          color="primary"
          indeterminate
        />
      </div>
      <div v-else>
        <statistics-chart
          :campaign-statistics="campaignStatistics"
          :is-disabled="isDisabled"
          :class="isDisabled && 'opacity-20'"
        />
        <div v-if="isDisabled" class="statistics-banner fill-height d-flex justify-center align-center text-center info--text">
          {{ $t('campaign.soon_statistics') }}
        </div>
      </div>
    </div>
    <div
      v-if="isMobile"
      class="d-flex justify-center"
    >
      <c-btn
        text
        class="px-2 primary--text text-decoration-underline font-weight-regular"
        :label="$t('campaign.go_to_statistics')"
        @click="goToStatistics()"
      />
    </div>
  </v-card>
</template>

<script>
  import DatePickerSelect from '@clickadilla/components/DatePickerSelect/index.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import routeNames from '@/types/route-names.js'
  import StatisticsChart from './StatisticsChart.vue'
  import gtmPush from '@/services/utils/gtm-push.js'

  const { CAMPAIGN_STATISTICS } = routeNames
  const { CAMPAIGN_ACTIONS } = GTM_EVENTS_CATEGORY
  const { CAMPAIGN_ACTION_STATISTICS } = GTM_EVENTS

  export default {
    name: 'Statistics',
    components: {
      DatePickerSelect,
      StatisticsChart,
      CBtn
    },
    props: {
      campaignId: {
        type: Number,
        required: true
      },
      campaignStatistics: {
        type: Array,
        required: true
      },
      statisticsDates: {
        type: Object,
        required: true
      },
      statisticsIsLoading: {
        type: Boolean,
        default: false
      },
      isDisabled: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xs
      }
    },
    methods: {
      goToStatistics() {
        gtmPush({
          event: CAMPAIGN_ACTION_STATISTICS,
          event_category: CAMPAIGN_ACTIONS,
          user_id: this.$auth.user?.id
        })
        this.$router.push({
          name: CAMPAIGN_STATISTICS,
          query: { campaigns: JSON.stringify([this.campaignId]) }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  :deep .width-200 .v-input__control {
    min-width: 210px;
  }
  :deep .picker > div{
    width: 100%;
    & input {
      text-align: center;
    }
  }
  .relative {
    position: relative;
  }
  .statistics-banner {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .legend-item {
    position: relative;

    &::before {
      content: '';
      border-radius: 50%;
      position: absolute;
      left: -10px;
      top: 33%;
      width: 6px;
      height: 6px;
      background-color: var(--v-info-base);
    }

    &--cost::before {
      background-color: var(--v-warning-base);
    }
  }
</style>
