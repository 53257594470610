<template>
  <v-card elevation="0">
    <v-card-title class="d-flex justify-space-between pa-0 text-h6 font-weight-medium black--text mb-1">
      {{ $t('campaign.targeting') }}
      <span
        v-if="campaignHasTargets"
        class="primary--text text-decoration-underline text-body-2 global-cursor-pointer"
        @click="modalIsShown = true"
      >
        {{ $t('campaign.targets.show_all') }}
      </span>
    </v-card-title>
    <v-card-text class="pb-0 px-1 pt-3">
      <target-blocks :campaign="campaign" :campaign-has-targets="campaignHasTargets" />
    </v-card-text>
    <targets-modal :is-shown="modalIsShown" :campaign="campaign" @close-modal="modalIsShown = false" />
  </v-card>
</template>

<script>
  import Campaign from '@/services/classes/Campaign.js'
  import TargetBlocks from '@/views/Campaign/TargetsPanel/TargetBlocks/Index.vue'
  import TargetsModal from '@/views/Campaign/TargetsPanel/TargetsModal.vue'

  export default {
    name: 'TargetsPanel',
    components: {
      TargetBlocks,
      TargetsModal
    },
    props: {
      campaign: {
        type: Campaign,
        required: true
      }
    },
    data() {
      return {
        modalIsShown: false
      }
    },
    computed: {
      campaignTargets() {
        return this.campaign.targets
      },
      campaignHasTargets() {
        return !(this.campaignTargets.inPageTrafficTarget === false
          && this.campaignTargets.ipv6Target.accept === true
          && this.campaignTargets.browserTarget.length === 0
          && !this.campaignTargets.ipRangesTarget.ranges
          && this.campaignTargets.countryTarget.length === 0
          && this.campaign.cpaGoals.length === 0
          && this.campaignTargets.regionTarget.length === 0
          && this.campaignTargets.cityTarget.length === 0
          && !this.campaignTargets.trafficTypeTarget.name
          && this.campaignTargets.languageTarget.length === 0
          && this.campaignTargets.operatingSystemTarget.length === 0
          && this.campaignTargets.placementTypeTarget.length === 0
          && this.campaignTargets.ip2LocationTypeTarget.length === 0
          && this.campaignTargets.deviceVendorTarget.length === 0
          && this.campaignTargets.deviceModelTarget.length === 0
          && this.campaignTargets.carrierTarget.carriers.length === 0
          && !this.campaignTargets.carrierTarget.type
          && this.campaignTargets.siteTarget.accept === null
          && this.campaignTargets.mobileAppTarget.mobileApps.length === 0
          && this.campaignTargets.categoryGroupTarget.length === 0
          && this.campaignTargets.providersTarget.selectedProviders.length === 0
          && this.campaignTargets.categoryTarget.length === 0
          && this.campaignTargets.trafficQualityType === null
          && this.campaignTargets.interestTarget.length === 0
          && !this.campaignTargets.uniquesTarget.accept
          && this.campaignTargets.spotTarget.spots.length === 0
          && !this.campaign.conversionType?.id
        )
      }
    }
  }
</script>
