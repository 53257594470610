<template>
  <v-sheet color="secondary-lighten" class="rounded pa-3 d-sm-flex py-4 px-5">
    <v-alert
      color="info"
      text
      :class="[
        'pa-3 text-caption mb-5 mb-sm-0',
        { 'width-50 mr-7 d-flex align-center': !isMobile }
      ]"
    >
      {{ $t('campaign.based_on_these_settings') }}
    </v-alert>
    <div :class="[{ 'width-50': !isMobile }]">
      <div class="d-flex align-start align-md-center flex-column flex-md-row mb-4 mb-md-3">
        {{ $t('campaign.recommended_price_adjustment') }}
        <div class="d-flex align-center">
          <div class="plus-minus-field ml-0 ml-md-3 white border-secondary rounded">
            <c-btn
              v-for="button in recommendButtons"
              :key="button.label"
              class="pa-0"
              :color="button.active ? 'primary' : 'transparent'"
              depressed
              :label="button.label"
              @click="setSign(button.label)"
            />
          </div>
          <c-text-field
            :id="seleniumIds.SELENIUM_TEST_ADJUSTMENT_AUTO_ADJUST_RECOMMENDED_PRICE_FIELD"
            :value="priceAdjustmentRatio"
            type="number"
            height="30"
            hide-details="auto"
            :error="errors.price_adjustment_ratio"
            background-color="white"
            class="max-width-55 border-secondary rounded text-field mx-1"
            @keypress="integerFilter($event)"
            @input="$emit('set-price-adjustment-ratio', +$event)"
          />
          <span class="font-weight-bold">%</span>
        </div>
      </div>
      <errors v-if="errors.price_adjustment_ratio" :error-messages="errors.price_adjustment_ratio" />

      <div
        class="d-flex align-start align-sm-center justify-space-between flex-column flex-sm-row"
      >
        <div class="max-price d-flex flex-wrap align-center">
          <span :class="['flex-grow-1 flex-md-grow-0 mr-md-2', { 'max-price': !isTablet }]">
            {{ $t('campaign.max_price') }}
          </span>
          <c-text-field
            :id="seleniumIds.SELENIUM_TEST_ADJUSTMENT_AUTO_ADJUST_MAX_PRICE_FIELD"
            :value="campaign.maxAdjustedPrice"
            type="number"
            height="30"
            hide-details="auto"
            background-color="white"
            :error="errors.max_adjusted_price"
            class="max-width-55 border-secondary rounded text-field mr-1"
            @input="$emit('set-max-adjust-price', $event)"
          />
          <span class="font-weight-bold align-self-center">$</span>
        </div>
        <c-btn
          class="mt-4 mt-md-0 mx-auto text-decoration-underline px-2 text-none font-weight-regular primary--text"
          :loading="saveIsLoading"
          :label="$t('campaign.save_parameters')"
          text
          small
          @click="$emit('save-parameters')"
        />
      </div>
      <errors v-if="errors.max_adjusted_price" :error-messages="errors.max_adjusted_price" />
    </div>
  </v-sheet>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import Errors from '@clickadilla/components/ui/Errors.vue'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import Campaign from '@/services/classes/Campaign.js'

  export default {
    name: 'AdjustmentAutoAdjust',
    components: {
      Errors,
      CTextField,
      CBtn
    },
    props: {
      campaign: {
        type: Campaign,
        required: true
      },
      priceAdjustmentRatio: {
        type: Number,
        required: true
      },
      errors: {
        type: Object,
        default: () => ({})
      },
      saveIsLoading: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      isTablet() {
        return this.$vuetify.breakpoint.mdAndUp
      },
      recommendButtons() {
        return [
          { active: this.priceAdjustmentRatio < 0, label: '-' },
          { active: !(this.priceAdjustmentRatio < 0), label: '+' }
        ]
      }
    },
    methods: {
      integerFilter(event) {
        if (!/^-?\d*$/.test(event.key)) event.preventDefault()
      },
      setSign(sign) {
        const price = Math.abs(this.priceAdjustmentRatio) * `${sign}1`
        this.$emit('set-price-adjustment-ratio', price)
        gtmPush({ event: GTM_EVENTS.PLUS_MINUS_REC_BID, event_category: GTM_EVENTS.EDIT_CAMPAIGN })
      }
    }
  }
</script>

<style scoped lang="scss">
  .width-50 {
    width: 50%;
  }
  .max-price {
    width: 100%;
  }
  .max-width-55 {
    max-width: 55px !important;
  }
  ::v-deep input[type='number'] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
  }
  ::v-deep.text-field {
    max-width: 100px;

    .v-input__slot {
      min-height: 30px !important;
      max-height: 30px !important;
    }
  }
  .plus-minus-field {
    min-width: 50px;

    .v-btn {
      border-radius: 4px 0 0 4px;
      max-width: 24px;
      min-width: 24px;
      min-height: 30px;
      max-height: 30px;

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }
</style>
