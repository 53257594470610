<template>
  <v-card outlined class="fill-height">
    <v-card-title class="py-3" style="font-size: 15px">
      {{ title }}
    </v-card-title>
    <v-divider />

    <v-card-text>
      <div v-for="(bid, key) in biddings" :key="key" class="d-flex flex-column mb-3">
        <div class="d-flex flex-row justify-space-between mb-2">
          <v-tooltip
            v-if="bid.sites.length > 1"
            content-class="text-subtitle-1 black white--text"
            max-width="1000"
            top
          >
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ bid.sites[0].domain }}
                <span> + {{ bid.sites.length - 1 }} more </span>
              </span>
            </template>
            <span v-for="(site, index) in bid.sites" :key="index">
              <span v-if="index !== 0" class="mr-3 mt-1">
                {{ index + 1 !== bid.sites.length ? `${site.domain},` : site.domain }}
              </span>
            </span>
          </v-tooltip>
          <div v-else>
            <span>
              {{ bid.sites[0].domain }}
            </span>
          </div>
          <div class="text-right mr-3 font-weight-medium">
            {{ bid.price }}$
          </div>
        </div>
        <v-divider />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'SiteBiddings',
    props: {
      biddings: {
        type: Array,
        required: true
      },
      title: {
        type: String,
        required: true
      }
    }
  }
</script>
