<template>
  <c-select
    :value="group"
    :items="campaignGroups"
    :loading="campaignGroupsAreLoading"
    :placeholder="$t('main.campaign_group')"
    item-text="name"
    item-value="id"
    height="30px"
    class="select-width"
    :disabled="disabled"
    hide-details
    @change="setCampaignGroup($event)"
  />
</template>

<script>
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import CampaignGroup from '@/services/classes/CampaignGroup.js'
  import campaignGroupsRepository from '@/services/repositories/campaign-groups-repository.js'
  import handleErrors from '@/services/handleErrors.js'
  import Campaign from '@/services/classes/Campaign.js'

  export default {
    name: 'CampaignGroup',
    components: {
      CSelect
    },
    props: {
      group: {
        type: CampaignGroup,
        required: true
      },
      campaign: {
        type: Campaign,
        required: true
      },
      disabled: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        campaignGroups: [],
        campaignGroupsAreLoading: false
      }
    },
    created() {
      this.fetchCampaignGroups()
    },
    methods: {
      async fetchCampaignGroups() {
        this.campaignGroupsAreLoading = true
        try {
          this.campaignGroups = await campaignGroupsRepository.index()
        } catch (error) {
          handleErrors(error)
        }
        this.campaignGroupsAreLoading = false
      },
      async setCampaignGroup(groupId) {
        try {
          await campaignGroupsRepository.pushCampaignsToGroup({
            campaignsIds: [this.campaign.id],
            campaignGroupId: +groupId
          })
          this.$emit('change')
        } catch (error) {
          handleErrors(error)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
 .select-width {
   min-width: 200px;
   max-width: 200px;
 }
</style>
