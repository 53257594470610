<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :mobile-breakpoint="0"
    hide-default-footer
    hide-default-header
    :items-per-page="-1"
  >
    <template #item.name="{ item }">
      <div class="d-flex text-caption secondary-darken--text line-height">
        <v-icon size="14" color="primary" class="mr-2">
          {{ item.icon }}
        </v-icon>
        {{ item.name }}
      </div>
    </template>
    <template #item.result="{ item }">
      <div class="text-caption font-weight-bold black--text line-height">
        <custom-chip-status
          v-if="item.status"
          label
          small
          :outlined="item.result.outlined"
          :value="item.result.value"
          :settings="item.result.settings"
          :min-width="100"
        />
        <v-chip
          v-else-if="item.chip"
          color="info"
          class="justify-center"
          label
          small
        >
          <span :class="['text-truncate']">{{ item.result.value }}</span>
        </v-chip>

        <span v-else-if="item.uniques">
          {{ item.result }}
          <template v-if="item.uniquesTargetLabel">
            <br />
            {{ item.uniquesTargetLabel }}
          </template>
        </span>

        <span v-else>
          {{ item.result }}
        </span>
      </div>
    </template>
  </v-data-table>
</template>

<script>
  import CustomChipStatus from '@/components/CustomChipStatus.vue'

  export default {
    name: 'GeneralPanelTable',
    components: { CustomChipStatus },
    props: {
      headers: {
        type: Array,
        required: true
      },
      items: {
        type: Array,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    height: 38px;
    padding: 0 4px;
  }
  .line-height {
    line-height: 0.9rem;
  }
</style>
