<template>
  <custom-chart
    :series="series"
    :options="options"
    type="line"
    height="240"
    width="100%"
  />
</template>

<script>
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import CustomChart from '@/components/CustomChart.vue'

  export default {
    name: 'StatisticsChart',
    components: {
      CustomChart
    },
    props: {
      campaignStatistics: {
        type: Array,
        required: true
      },
      isDisabled: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        axis: {
          first: 'cost',
          second: 'impressions'
        }
      }
    },
    computed: {
      yaxis() {
        return [
          {
            show: false,
            title: {
              text: this.$t(`campaign_statistics.headers.${this.axis.first}`)
            }
          },
          {
            show: false,
            opposite: true,
            title: {
              text: this.$t(`campaign_statistics.headers.${this.axis.second}`)
            }
          }
        ]
      },
      categories() {
        return this.campaignStatistics.map(
          ({ date }) => (date)
        )
      },
      firstSeries() {
        const series = this.isDisabled
          ? this.createRandomArray()
          : this.campaignStatistics.map((item) => toFixedByDefault(parseFloat(item[this.axis.first])))
        return {
          name: this.$t(`campaign.${this.axis.first}`),
          type: 'area',
          data: series
        }
      },
      secondSeries() {
        const series = this.isDisabled
          ? this.createRandomArray()
          : this.campaignStatistics.map((item) => toFixedByDefault(parseFloat(item[this.axis.second])))
        return {
          name: this.$t(`campaign.${this.axis.second}`),
          type: 'area',
          data: series
        }
      },
      series() {
        return [this.firstSeries, this.secondSeries]
      },
      options() {
        return {
          yaxis: this.yaxis,
          xaxis: {
            type: 'datetime',
            categories: this.categories
          },
          chart: {
            toolbar: {
              show: false
            }
          },
          stroke: {
            width: [3, 3],
            curve: 'smooth'
          },
          fill: {
            type: 'gradient',
            gradient: {
              type: 'vertical',
              opacityFrom: 0.3,
              opacityTo: 0.2
            }
          },
          colors: [
            this.isDisabled
              ? this.$vuetify.theme.themes.light.black
              : this.$vuetify.theme.themes.light.warning,
            this.isDisabled
              ? this.$vuetify.theme.themes.light.black
              : this.$vuetify.theme.themes.light.info
          ],
          legend: {
            show: false
          }
        }
      }
    },
    methods: {
      createRandomArray() {
        return new Array(this.categories.length).fill().map(() => Math.random())
      }
    }
  }
</script>
