<template>
  <v-card elevation="0" class="px-4 py-5">
    <v-card-title class="d-flex pa-0 text-h6 font-weight-medium black--text">
      {{ $t('campaign.pricing') }}
      <div
        v-if="priceDifference < 0 && priceIsAutoAdjustable && !campaign.isFlatDeal"
        class="ml-2 px-2 py-1 rounded error white--text text-body-2 font-weight-bold"
      >
        {{ $t('campaign.low_price') }}
      </div>
    </v-card-title>
    <v-card-text class="pa-0 mt-3">
      <v-skeleton-loader :loading="isLoading" type="table-heading, table-heading">
        <v-container class="ma-0" fluid>
          <v-row>
            <v-col v-if="!priceModelIsGoal && !campaign.isFlatDeal" class="pa-0 pr-1 pr-md-3" cols="7" md="3">
              <div class="pa-3 fill-height secondary-lighten rounded">
                <div class="pr-1 pr-md-0 mb-1 mb-md-2 text-caption black--text">
                  {{ $t('campaign.auto_adjust_price') }}
                </div>
                <div class="d-flex align-center">
                  <c-switch
                    class="black--text"
                    readonly
                    :input-value="autoAdjustIsEnabled"
                    :loading="autoAdjustIsLoading"
                    @click="autoAdjustToggler()"
                  >
                    <template #label>
                      <span :class="['text-truncate black--text', { 'width-50': isMobile }]">
                        {{ autoAdjustIsEnabled ? $t('campaign.on') : $t('campaign.off') }}
                      </span>
                    </template>
                  </c-switch>
                  <c-info-btn
                    v-if="helpUrls.auto_adjust_help"
                    class="ml-2"
                    size="20"
                    :link="helpUrls.auto_adjust_help"
                  />
                </div>
              </div>
            </v-col>
            <v-col v-if="campaign.isFlatDeal" class="pa-0 pr-1 pr-md-3" cols="5" :md="3">
              <div class="fill-height d-flex flex-column align-start black--text secondary-lighten rounded pa-3">
                <div class="text-caption black--text mb-1">
                  {{ $t('campaign.actual_price') }}
                </div>
                <div class="text-h6 font-weight-bold primary--text">
                  ${{ campaign.price }}
                </div>
              </div>
            </v-col>
            <v-col order-md="3" class="pa-0" cols="5" :md="priceModelIsGoal ? 3 : 2">
              <div class="fill-height d-flex flex-column align-start secondary-lighten rounded pa-3">
                <div class="text-caption black--text mb-2 mb-md-3">
                  {{ $t('campaign.pricing_model') }}
                </div>
                <custom-chip-status
                  class="mt-auto"
                  label
                  small
                  outlined
                  :settings="pricingModels"
                  :value="campaign.pricingModel"
                />
              </div>
            </v-col>
            <v-col v-if="!priceModelIsGoal && !campaign.isFlatDeal" order-md="4" cols="12" class="pa-0">
              <v-expand-transition>
                <div v-show="!priceModelIsGoal && autoAdjustIsEnabled">
                  <adjustment-auto-adjust
                    class="mt-1 mt-md-3"
                    :campaign="campaign"
                    :price-adjustment-ratio="+priceAdjustmentRatio"
                    :save-is-loading="saveIsLoading"
                    :errors="errors"
                    @set-price-adjustment-ratio="priceAdjustmentRatio = $event"
                    @set-max-adjust-price="setMaxAdjustPrice($event)"
                    @save-parameters="saveParameters()"
                  />
                  <span v-if="errors.price" class="error--text text-caption">{{ errors.price[0] }}</span>
                </div>
              </v-expand-transition>
            </v-col>
            <v-col v-if="!priceModelIsGoal && !campaign.isFlatDeal" class="my-1 my-md-0 pa-0 pr-md-3" cols="12" md="7">
              <v-row no-gutters class="fill-height pa-3 secondary-lighten rounded black--text">
                <v-col cols="4">
                  <div class="text-caption mb-1 text-truncate">
                    {{ $t('campaign.rec_price') }}
                  </div>
                  <span class="text-h6 font-weight-bold">
                    ${{ campaign.recommendedPrice }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <div class="text-caption mb-1">
                    {{ $t('campaign.actual_price') }}
                  </div>
                  <div class="d-flex align-center">
                    <span :class="['text-h6 font-weight-bold', priceDifference < 0 ? 'error--text' : 'primary--text']">
                      ${{ campaign.price }}
                    </span>
                    <v-icon v-if="priceDifference < 0" color="error" size="16" class="ml-1">
                      $arrow-with-stick
                    </v-icon>
                  </div>
                </v-col>
                <v-col cols="4" class="d-flex align-self-end align-self-lg-center align-self-xl-end">
                  <c-btn
                    text
                    small
                    :loading="priceIsLoading"
                    :disabled="adjustButtonIsDisable && autoAdjustIsEnabled"
                    :class="[
                      'text-decoration-underline px-2 text-none font-weight-regular',
                      adjustButtonIsDisable ? 'text--secondary' : 'primary--text'
                    ]"
                    :label="$t('campaign.adjust_price')"
                    @click="setRecommendedPrice()"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
  import CSwitch from '@clickadilla/components/ui/CSwitch.vue'
  import CInfoBtn from '@clickadilla/components/ui/CInfoBtn.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapGetters, mapState } from 'vuex'
  import { pricingModelTypes } from '@clickadilla/components/constants/pricing-model-types.js'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import AdjustmentAutoAdjust from './AdjustmentAutoAdjust.vue'
  import campaignsRepository from '@/services/repositories/campaigns-repository.js'
  import handleErrors from '@/services/handleErrors.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'
  import Campaign from '@/services/classes/Campaign.js'

  export default {
    name: 'PricePanel',
    components: {
      CustomChipStatus,
      CSwitch,
      CInfoBtn,
      CBtn,
      AdjustmentAutoAdjust
    },
    props: {
      campaign: {
        type: Campaign,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        autoAdjustIsLoading: false,
        autoAdjustIsEnabled: false,
        priceIsLoading: false,
        errors: {},
        priceAdjustmentRatio: 0,
        maxAdjustedPrice: null,
        saveIsLoading: false
      }
    },
    computed: {
      ...mapState('campaigns', ['pricingModels']),
      ...mapGetters('settings', ['helpUrls']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.lgOnly
      },
      recommendedPrice() {
        return this.campaign.recommendedPrice
      },
      adjustButtonIsDisable() {
        return this.priceDifference === 0 || !this.priceIsAutoAdjustable
      },
      priceIsAutoAdjustable() {
        return this.campaign.actions.price_auto_adjustable
      },
      priceDifference() {
        return this.campaign.price - this.recommendedPrice
      },
      priceModelIsGoal() {
        return [
          pricingModelTypes.CPA,
          pricingModelTypes.CPM_GOAL,
          pricingModelTypes.CPC_GOAL
        ].includes(this.campaign.pricingModel)
      }
    },
    watch: {
      campaign(campaign) {
        this.priceAdjustmentRatio = (campaign.priceAdjustmentRatio * 100).toFixed()
        this.maxAdjustedPrice = campaign.maxAdjustedPrice
        this.autoAdjustIsEnabled = campaign.enablePriceAutoAdjust
      }
    },
    methods: {
      async saveParameters() {
        this.saveIsLoading = true
        const adjustmentRatio = this.recommendedPrice * (this.priceAdjustmentRatio / 100)
        const adjustedPrice = +(this.recommendedPrice + +adjustmentRatio).toFixed(4)
        const requestIsSuccess = await this.updatePriceAndAutoAdjust({
          price: adjustedPrice,
          enablePriceAutoAdjust: this.autoAdjustIsEnabled,
          maxAdjustedPrice: this.maxAdjustedPrice,
          priceAdjustmentRatio: this.priceAdjustmentRatio / 100
        })
        if (requestIsSuccess) {
          this.$emit('update-campaign')
          const adjustmentNotification = this.campaign.enablePriceAutoAdjust
            ? 'campaign.adjustments_successfully_changed'
            : 'campaign.auto_adjust_on'

          const adjustmentEvent = this.campaign.enablePriceAutoAdjust
            ? GTM_EVENTS.SAVE_AUTOADJUST
            : GTM_EVENTS.AUTO_ADJUST_ON

          this.$showSuccessNotification(this.$t(adjustmentNotification))
          gtmPush({ event: adjustmentEvent, event_category: GTM_EVENTS.EDIT_CAMPAIGN })
        }
        this.saveIsLoading = false
      },
      setMaxAdjustPrice(value) {
        this.maxAdjustedPrice = value ? +value : null
      },
      async setRecommendedPrice() {
        if (this.adjustButtonIsDisable) return

        this.priceIsLoading = true
        await this.updatePriceAndAutoAdjust({
          enablePriceAutoAdjust: this.autoAdjustIsEnabled,
          price: this.recommendedPrice
        })
        gtmPush({ event: GTM_EVENTS.ADJUST_PRICE, event_category: GTM_EVENTS.EDIT_CAMPAIGN })
        setTimeout(() => {
          this.priceIsLoading = false
          this.$showSuccessNotification(this.$t('campaign.adjust_success'))
        }, 2000)
      },
      async autoAdjustToggler() {
        this.autoAdjustIsEnabled = !this.autoAdjustIsEnabled
        if (!this.autoAdjustIsEnabled && this.campaign.enablePriceAutoAdjust) {
          await this.updatePriceAndAutoAdjust({
            enablePriceAutoAdjust: false,
            price: this.campaign.price
          })
          this.$showSuccessNotification(this.$t('campaign.auto_adjust_off'))
          gtmPush({ event: GTM_EVENTS.AUTO_ADJUST_OFF, event_category: GTM_EVENTS.EDIT_CAMPAIGN })
        }
      },
      async updatePriceAndAutoAdjust(params) {
        this.errors = {}
        try {
          const transformedParams = Campaign.transformForUpdatePriceRequest(params)
          await campaignsRepository.price(this.campaign.id, transformedParams)
          this.$emit('update-campaign')
          return true
        } catch (error) {
          this.errors = handleErrors(error)
          return false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
 .width-120 {
   max-width: 120px;
 }
 .width-50 {
   max-width: 50px;
 }
</style>
