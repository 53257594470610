<template>
  <v-card elevation="0" class="fill-height">
    <v-card-title class="text-h6 font-weight-medium black--text">
      {{ $t('campaign.general_info') }}
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader
        :loading="isLoading"
        type="table-heading, table-heading, table-heading, table-heading"
      >
        <general-panel-table
          :headers="headers"
          :items="tableItems"
          @update="$emit('update')"
        />
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import { mapGetters, mapState } from 'vuex'
  import moment from 'moment'
  import GeneralPanelTable from '@/views/Campaign/GeneralPanel/GeneralPanelTable.vue'
  import Campaign from '@/services/classes/Campaign.js'

  export default {
    name: 'GeneralPanel',
    components: { GeneralPanelTable },
    props: {
      campaign: {
        type: Campaign,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      ...mapState('settings', ['smartBiddingByAdFormat']),
      ...mapGetters('approvables', ['approvalStatusTypes']),
      headers() {
        return [
          { value: 'name', align: 'start' },
          { value: 'result', align: 'end' }
        ]
      },
      tableItems() {
        if (this.isLoading) return []

        const tableItems = [
          {
            name: this.$t('main.ad_format'),
            icon: '$sound',
            result: { value: this.campaign.adFormat.name },
            chip: true
          },
          {
            name: this.$t('main.approval_status'),
            icon: '$information',
            result: this.approvalStatuses,
            status: true
          },
          {
            name: this.$t('campaign.reject_details'),
            icon: '$file',
            result: this.campaign.latestApproval.rejectCause.details
          },
          { name: this.$t('campaign.reject_cause'), icon: '$file', result: this.campaign.latestApproval.rejectCause.name },
          { name: this.$t('campaign.cause_details'), icon: '$file', result: this.campaign.latestApproval.details },
          { name: this.$t('campaign.smart_bidding_enabled'), icon: '$rtb', result: this.smartBidding },
          {
            name: this.$t('campaign.reserved_balance'),
            icon: '$dollar-outlined',
            result: `$${toFixedByDefault(this.campaign.reservedBalance)}`
          },
          { name: this.$t('campaign.activate_on_approve'), icon: '$check', result: this.activateOnApprove },
          { name: this.$t('campaign.created_at'), icon: '$clock', result: moment(this.campaign.createdAt).format('YYYY.MM.DD (HH:mm:ss)') },
          { name: this.$t('campaign.subscription_time'), icon: '$billings', result: this.subscriptionDaysNumber },
          { name: this.$t('campaign.activate'), icon: '$calendar', result: this.activeDateRange }
        ]
        return tableItems.filter(({ result }) => !!result)
      },
      activeDateRange() {
        const activeFrom = this.campaign.activeFrom ? moment(this.campaign.activeFrom).format('YYYY.MM.DD') : null
        const activeTo = this.campaign.activeTo ? moment(this.campaign.activeTo).format('YYYY.MM.DD') : null
        if (activeFrom && activeTo) {
          return `${activeFrom} - ${activeTo}`
        }
        return ''
      },
      approvalStatuses() {
        return { value: this.campaign.latestApproval.status, settings: this.approvalStatusTypes }
      },
      smartBidding() {
        if (this.smartBiddingByAdFormat[this.campaign.adFormat.type]) {
          const isPricingModel = this.smartBiddingByAdFormat[this.campaign.adFormat.type].includes(
            this.campaign.pricingModel
          )
          if (isPricingModel) {
            return this.campaign.smartBidding
              ? this.$t('main.yes')
              : this.$t('main.no')
          }
        }
        return null
      },
      subscriptionDaysNumber() {
        const { webPushSubscriptionTimeTarget } = this.campaign.targets

        if (webPushSubscriptionTimeTarget.minDays !== null
          && webPushSubscriptionTimeTarget.maxDays !== null
        ) {
          return `${webPushSubscriptionTimeTarget.minDays}-${webPushSubscriptionTimeTarget.maxDays} ${this.$t('campaign.only_days')}`
        }
        if (webPushSubscriptionTimeTarget.minDays !== null) {
          return this.$t('campaign.days_from', {
            n: webPushSubscriptionTimeTarget.minDays
          })
        }
        if (webPushSubscriptionTimeTarget.maxDays !== null) {
          return this.$t('campaign.days', {
            n: webPushSubscriptionTimeTarget.maxDays
          })
        }
        return ''
      },
      activateOnApprove() {
        return this.campaign.activateOnApprove
          ? this.$t('main.yes')
          : this.$t('main.no')
      }
    }
  }
</script>
