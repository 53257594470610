<template>
  <div class="d-flex flex-wrap align-center black rounded pa-4 pl-5">
    <v-skeleton-loader :loading="isLoading" type="chip" class="d-flex align-center">
      <span class="text-h6 white--text ">
        {{ title }}
      </span>
      <custom-chip-status
        v-if="campaign.status"
        :value="campaign.status"
        :settings="statuses"
        class="ml-4"
        label
        small
        :min-width="100"
      />
      <v-tooltip v-if="campaign.isFlatDeal" top>
        <template #activator="{ on, attrs }">
          <v-icon
            class="global-cursor-pointer ml-2"
            color="primary"
            size="18"
            v-bind="attrs"
            v-on="on"
          >
            $cart
          </v-icon>
        </template>
        <span>{{ $t('campaign.your_direct_campaign') }}</span>
      </v-tooltip>
    </v-skeleton-loader>
    <v-spacer />
    <campaign-group
      :group="campaign.campaignGroup"
      :campaign="campaign"
      :disabled="isLoading"
      @change="$emit('update')"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import CampaignGroup from '@/views/Campaign/HeaderPanel/CampaignGroup.vue'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'
  import Campaign from '@/services/classes/Campaign.js'

  export default {
    name: 'HeaderPanel',
    components: {
      CampaignGroup,
      CustomChipStatus
    },
    props: {
      campaign: {
        type: Campaign,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      ...mapState('campaigns', ['statuses']),
      title() {
        return `${this.campaign.name} #${this.campaign.id}`
      }
    }
  }
</script>
