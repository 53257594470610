<template>
  <v-dialog :value="isShown" max-width="70%" @input="closeDialog()">
    <v-card class="pa-3">
      <div class="d-flex justify-space-between align-start text-h6 font-weight-medium black--text">
        <div class="pa-2">
          {{ $t('campaign.targeting') }}
        </div>
        <v-icon size="16" @click="closeDialog()">
          $close-outlined
        </v-icon>
      </div>
      <target-blocks :campaign="campaign" no-limits campaign-has-targets class="pa-2" />
    </v-card>
  </v-dialog>
</template>

<script>
  import Campaign from '@/services/classes/Campaign.js'
  import TargetBlocks from '@/views/Campaign/TargetsPanel/TargetBlocks/Index.vue'

  export default {
    name: 'TargetsModal',
    components: { TargetBlocks },
    props: {
      campaign: {
        type: Campaign,
        required: true
      },
      isShown: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      closeDialog() {
        this.$emit('close-modal')
      }
    }
  }
</script>
