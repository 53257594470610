<template>
  <v-row :class="isSmAndDown && 'gap-2'">
    <v-col
      v-for="(option, index) in indicators"
      :key="index"
      :class="isSmAndDown ? 'gap-2' : 'pa-1 py-lg-0'"
      cols="6"
      sm="3"
      md="12"
      lg="3"
    >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            :class="[
              'fill-height white rounded pa-3 d-flex flex-column justify-space-between',
              { 'opacity-40': !+option.value && (option.type !== 'chip') }
            ]"
            v-on="on"
          >
            <div class="text-caption black--text">
              {{ option.name }}
            </div>
            <v-skeleton-loader :loading="statisticsIsLoading" type="chip">
              <custom-chip-status
                v-if="option.type === 'chip' && option.value"
                label
                small
                :value="option.value.value"
                :settings="option.value.settings"
                :min-width="100"
              />
              <div v-else class="text-h6 font-weight-medium black--text">
                {{ `${option.prefix || ''}${option.value}${option.suffix || ''}` }}
              </div>
            </v-skeleton-loader>
          </div>
        </template>
        <div class="text-pre-line">
          {{ option.tooltip }}
        </div>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'
  import { pricingModelTypes } from '@clickadilla/components/constants/pricing-model-types.js'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import Campaign from '@/services/classes/Campaign.js'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'

  export default {
    name: 'CampaignInfo',
    components: { CustomChipStatus },
    props: {
      campaign: {
        type: Campaign,
        required: true
      },
      campaignStatistics: {
        type: Array,
        required: true
      },
      statisticsIsLoading: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState('campaigns', ['cpaStatuses']),
      isSmAndDown() {
        return this.$vuetify.breakpoint.smAndDown
      },
      campaignCPMItems() {
        return [
          {
            name: this.$t('campaign.impressions'),
            value: this.summarizeHandler('impressions'),
            tooltip: this.$t('campaign.impressions_info'),
            isShown: true
          },
          {
            name: this.$t('campaign.winrate'),
            value: this.averageWinrate,
            suffix: '%',
            tooltip: this.$t('campaign.winrate_info'),
            isShown: this.hasCampaignFormat(this.campaign.adFormat.type)
          },
          {
            name: this.$t('campaign.average_price'),
            value: this.averagePrice,
            prefix: '$',
            tooltip: this.$t('campaign.average_price_info'),
            isShown: !this.hasCampaignFormat(this.campaign.adFormat.type)
          },
          {
            name: this.$t('campaign.average_spend'),
            value: this.averageSpend,
            prefix: '$',
            tooltip: this.$t('campaign.daily_spend_info'),
            isShown: !this.hasCampaignFormat(this.campaign.adFormat.type)
          },
          {
            name: this.$t('campaign.daily_spend'),
            value: this.averageSpend,
            prefix: '$',
            tooltip: this.$t('campaign.daily_spend_info'),
            isShown: this.hasCampaignFormat(this.campaign.adFormat.type)
          },
          {
            name: this.$t('campaign.conversions'),
            value: this.summarizeHandler('conversions'),
            tooltip: this.$t('campaign.conversions_info'),
            isShown: true
          }
        ].filter(({ isShown }) => isShown)
      },
      campaignCPCItems() {
        return [
          {
            name: this.$t('campaign.clicks'),
            value: this.summarizeHandler('clicks'),
            tooltip: this.$t('campaign.clicks_info'),
            isShown: true
          },
          {
            name: 'CTR',
            value: this.summarizeHandler('ctr'),
            tooltip: this.$t('campaign.ctr_info'),
            isShown: true
          },
          {
            name: this.$t('campaign.average_price'),
            value: this.averagePrice,
            prefix: '$',
            tooltip: this.$t('campaign.average_price_info'),
            isShown: !this.hasCampaignFormat(this.campaign.adFormat.type)
          },
          {
            name: this.$t('campaign.winrate'),
            value: this.averageWinrate,
            suffix: '%',
            tooltip: this.$t('campaign.winrate_info'),
            isShown: this.hasCampaignFormat(this.campaign.adFormat.type)
          },
          {
            name: this.$t('campaign.average_spend'),
            value: this.averageSpend,
            prefix: '$',
            tooltip: this.$t('campaign.daily_spend_info'),
            isShown: !this.hasCampaignFormat(this.campaign.adFormat.type)
          },
          {
            name: this.$t('campaign.daily_spend'),
            value: this.averageSpend,
            prefix: '$',
            tooltip: this.$t('campaign.daily_spend_info'),
            isShown: this.hasCampaignFormat(this.campaign.adFormat.type)
          }
        ].filter(({ isShown }) => isShown)
      },
      campaignGoalItems() {
        return [
          {
            name: this.$t('campaign.conversions'),
            value: this.summarizeHandler('conversions'),
            tooltip: this.$t('campaign.conversions_info')
          },
          {
            name: this.$t('campaign.goals'),
            value: this.campaignGoals,
            tooltip: this.$t('campaign.goals_info')
          },
          {
            name: this.$t('campaign.average_spend'),
            value: this.averageSpend,
            tooltip: this.$t('campaign.daily_spend_info')
          },
          {
            name: this.$t('campaign.cpa_status'),
            type: 'chip',
            value: this.cpaStatus,
            tooltip: this.$t('campaign.status_spa_info')
          }
        ]
      },
      campaignCPAItems() {
        return [
          {
            name: this.$t('campaign.impressions'),
            value: this.summarizeHandler('impressions'),
            tooltip: this.$t('campaign.impressions_info')
          },
          {
            name: this.$t('campaign.conversions'),
            value: this.summarizeHandler('conversions'),
            tooltip: this.$t('campaign.conversions_info')
          },
          {
            name: this.$t('campaign.goals'),
            value: this.campaignGoals,
            tooltip: this.$t('campaign.goals_info')
          },
          {
            name: this.$t('campaign.average_spend'),
            value: this.averageSpend,
            tooltip: this.$t('campaign.daily_spend_info')
          }
        ]
      },
      indicators() {
        const campaignModels = {
          [pricingModelTypes.CPM]: this.campaignCPMItems,
          [pricingModelTypes.CPC]: this.campaignCPCItems,
          [pricingModelTypes.CPA]: this.campaignCPAItems,
          [pricingModelTypes.CPM_GOAL]: this.campaignGoalItems,
          [pricingModelTypes.CPC_GOAL]: this.campaignGoalItems
        }
        return campaignModels[this.campaign.pricingModel]
      },
      cpaStatus() {
        return { value: this.campaign.cpaStatus, settings: this.cpaStatuses }
      },
      campaignGoals() {
        return this.campaign.cpaGoals.length
      },
      averageWinrate() {
        return this.campaignStatistics.length ? ((this.summarizeHandler('winRate') / this.campaignStatistics.length) * 100).toFixed(2) : '0.00'
      },
      averagePrice() {
        const sumImpressions = +this.summarizeHandler('impressions')
        return sumImpressions ? ((this.summarizeHandler('cost') / sumImpressions) * 1000).toFixed(4) : '0.0000'
      },
      averageSpend() {
        return this.campaignStatistics.length ? (this.summarizeHandler('cost') / this.campaignStatistics.length).toFixed(4) : '0.0000'
      }
    },
    methods: {
      summarizeHandler(param) {
        if (!this.campaignStatistics.length) return 0

        return this.campaignStatistics
          .reduce((acc, statItem) => +statItem[param] + acc, 0)
          .toFixed(4)
          .replace('.0000', '')
      },
      hasCampaignFormat(format) {
        return [adFormats.BANNER, adFormats.IN_STREAM_AD].includes(format)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .gap-2 {
    padding: 2px;
  }
</style>
