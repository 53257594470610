<template>
  <v-card elevation="0">
    <v-card-title class="text-h6 font-weight-medium black--text pb-2">
      {{ $t('campaign.limits') }}
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader :loading="isLoading" type="table-heading, table-heading">
        <v-data-table
          v-if="limits"
          :headers="headers"
          :items="limits"
          hide-default-header
          hide-default-footer
          :mobile-breakpoint="0"
          :items-per-page="-1"
        >
          <template #item.name="{ item }">
            <div class="d-flex text-caption secondary-darken--text px-0">
              {{ item.name }}
            </div>
          </template>
          <template #item.result="{ item }">
            <div class="text-caption font-weight-bold black--text text-end">
              {{ item.result }}
            </div>
          </template>
        </v-data-table>
        <div v-else>
          {{ $t('campaign.no_limits') }}
        </div>
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
  import Campaign from '@/services/classes/Campaign.js'
  import limitTypes from '@/types/limit-types.js'

  export default {
    name: 'LimitsPanel',
    props: {
      campaign: {
        type: Campaign,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      headers() {
        return [
          { value: 'name', align: 'start' },
          { value: 'result', align: 'center' }
        ]
      },
      limits() {
        const limits = [
          { name: this.$t('campaign.limits_type'), result: this.limitTypeLabel },
          { name: this.$t('campaign.hourly_limit'), result: this.campaign.hourlyLimit },
          { name: this.$t('campaign.daily_limit'), result: this.campaign.dailyLimit },
          { name: this.$t('campaign.total_limit'), result: this.campaign.totalLimit },
          { name: this.$t('campaign.limit_rate'), result: this.smoothSpendRate }
        ]
        return limits.filter(({ result }) => !!result)
      },
      smoothSpendRate() {
        return this.campaign.targets.throttleTarget ?? this.$t('main.no')
      },
      limitTypeLabel() {
        return {
          [limitTypes.IMPRESSIONS]: this.$t('campaign.impressions'),
          [limitTypes.CLICKS]: this.$t('campaign.clicks'),
          [limitTypes.MONEY]: this.$t('campaign.money')
        }[this.campaign.limitType]
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    height: 38px;
    padding: 0 4px;
  }
</style>
