<template>
  <v-card elevation="0" class="border-secondary" height="100%">
    <v-card-title class="black--text text-body-2 pa-3 pb-0">
      {{ title }}
      <span class="text-caption font-weight-medium primary--text ml-2">
        {{ sitesDomains.length || '' }}
      </span>
    </v-card-title>
    <v-card-text class="px-3 pt-0 pb-1">
      <v-progress-circular v-if="isLoading" indeterminate color="primary" size="19" width="2" />
      <card-items v-else :items="sitesDomains" :limit="itemLimits" :no-limits="noLimits" />
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex'
  import CardItems from './CardItems.vue'
  import sitesRepository from '@/services/repositories/sites-repository.js'
  import Campaign from '@/services/classes/Campaign.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'SitesCard',
    components: { CardItems },
    props: {
      title: {
        type: String,
        required: true
      },
      sites: {
        type: Array,
        required: true
      },
      premiumSitesIsAll: {
        type: Boolean,
        default: false
      },
      noLimits: {
        type: Boolean,
        default: false
      },
      campaign: {
        type: Campaign,
        required: true
      }
    },
    data() {
      return {
        allPremiumSitesDomains: [],
        isLoading: false,
        itemLimits: 10
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      sitesDomains() {
        if (this.premiumSitesIsAll) {
          return this.allPremiumSitesDomains.map((site) => (
            { name: this.adNetwork.showSiteDomains ? site.domain : site.id }
          ))
        }
        return this.sites.map((site) => (
          { name: this.adNetwork.showSiteDomains ? site.domain : site.id }
        ))
      }
    },
    created() {
      if (this.premiumSitesIsAll) {
        this.fetchPremiumSites()
      }
    },
    methods: {
      async fetchPremiumSites() {
        this.isLoading = true
        try {
          this.allPremiumSitesDomains = await sitesRepository.findPremium({
            adFormatId: this.campaign.adFormat.id,
            pricingModel: this.campaign.pricingModel
          })
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      }
    }
  }
</script>
