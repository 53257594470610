<template>
  <v-card elevation="0" class="border-secondary" height="100%">
    <v-card-title class="black--text opacity-60 text-body-2 pa-3 pb-0">
      {{ title }}
    </v-card-title>
    <v-card-text class="px-3 pt-0 pb-1">
      <div v-for="(cpaGoal, i) in cpaGoals" :key="i" class="mb-1">
        <div>${{ cpaGoal.price }}</div>
        <card-items :limit="itemLimits" :items="cpaGoal.countries" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import CardItems from './CardItems.vue'

  export default {
    name: 'CpaGoalsCard',
    components: { CardItems },
    props: {
      title: {
        type: String,
        required: true
      },
      cpaGoals: {
        type: Array,
        required: true
      },
      limit: {
        type: [Number, Boolean],
        default: 10
      }
    },
    data() {
      return {
        itemLimits: this.limit || 10
      }
    }
  }
</script>
