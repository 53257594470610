<template>
  <div class="d-flex flex-wrap">
    <span v-for="(item, index) in limitedItems" :key="index" class="text-caption">
      {{ index + 1 !== limitedItems.length ? `${item.name + ','}` : item.name }}&nbsp;
    </span>
  </div>
</template>

<script>

  export default {
    name: 'LimitedItems',
    props: {
      items: {
        type: Array,
        required: true
      },
      limit: {
        type: Number,
        required: true
      },
      noLimits: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      limitedItems() {
        return this.noLimits ? this.items : this.items.slice(0, this.limit)
      }
    }
  }
</script>
